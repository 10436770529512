export var HeaderFooterEvents;
(function (HeaderFooterEvents) {
    HeaderFooterEvents["setlistenerready"] = "hn-webcomp-header-footer-event-setlistenerready";
    HeaderFooterEvents["setfirsttimelogin"] = "hn-webcomp-header-footer-event-setfirsttimelogin";
    HeaderFooterEvents["setanonymousheader"] = "hn-webcomp-header-footer-event-setanonymousheader";
    HeaderFooterEvents["sethiddenheader"] = "hn-webcomp-header-footer-event-sethiddenheader";
    HeaderFooterEvents["setdriftsmeldingpath"] = "hn-webcomp-header-footer-event-setdriftsmeldingpath";
    HeaderFooterEvents["sethiddenfooter"] = "hn-webcomp-header-footer-event-sethiddenfooter";
    HeaderFooterEvents["setnomarginfooter"] = "hn-webcomp-header-footer-event-setnomarginfooter";
    HeaderFooterEvents["setvarslinger"] = "hn-webcomp-header-footer-event-setvarslinger";
    HeaderFooterEvents["refreshvarslinger"] = "hn-webcomp-header-footer-event-refreshvarslinger";
    /** @deprecated Slett refreshvarslingeroghendelsesmeny, refreshvarslinger brukes i stedet */
    HeaderFooterEvents["refreshvarslingeroghendelsesmeny"] = "hn-webcomp-header-footer-event-refreshvarslingeroghendelsesmeny";
    /**
     * @todo: Flytt til en PersonvelgerEvents-enum
     */
    HeaderFooterEvents["setonshowsignoutbox"] = "hn-webcomp-header-footer-event-setonshowsignoutbox";
    /**
     * @deprecated Slett når alle har tatt i bruk v31
     */
    HeaderFooterEvents["setfromlocation"] = "hn-webcomp-header-footer-event-setfromlocation";
    /**
     * @todo: Flytt til en PersonvelgerEvents-enum
     */
    HeaderFooterEvents["setredirectto"] = "hn-webcomp-header-footer-event-setredirectto";
    /**
     * @todo: Flytt til en PersonvelgerEvents-enum
     */
    HeaderFooterEvents["setredirecttourlafterlogin"] = "hn-webcomp-header-footer-event-setredirecttourlafterlogin";
    HeaderFooterEvents["setloginredirecturl"] = "hn-webcomp-header-footer-event-setloginredirecturl";
    HeaderFooterEvents["setnavigate"] = "hn-webcomp-header-footer-event-setnavigate";
    HeaderFooterEvents["setlocation"] = "hn-webcomp-header-footer-event-setlocation";
    HeaderFooterEvents["setuserloading"] = "hn-webcomp-header-footer-event-setuserloading";
    /**
     * @todo: Flytt til en PersonvelgerEvents-enum
     */
    HeaderFooterEvents["setvispersonvelger"] = "hn-webcomp-header-footer-event-setvispersonvelger";
    HeaderFooterEvents["setsurveys"] = "hn-webcomp-header-footer-event-setsurveys";
    HeaderFooterEvents["setfooterdata"] = "hn-webcomp-header-footer-event-setfooterdata";
    /**
     * @todo: Flytt til en PersonvelgerEvents-enum
     */
    HeaderFooterEvents["soonautosignout"] = "hn-webcomp-header-footer-event-soonautosignout";
    HeaderFooterEvents["showappinvitation"] = "hn-webcomp-header-footer-event-showappinvitation";
})(HeaderFooterEvents || (HeaderFooterEvents = {}));
export var CmsBlocksEvents;
(function (CmsBlocksEvents) {
    CmsBlocksEvents["setHiddenPromopanel"] = "hn-webcomp-cms-blocks-event-sethiddenpromopanel";
})(CmsBlocksEvents || (CmsBlocksEvents = {}));
export var CommonEvents;
(function (CommonEvents) {
    CommonEvents["isMounted"] = "hn-webcomp-common-ismounted";
    CommonEvents["setLanguage"] = "hn-language-event-set-language";
    CommonEvents["attemptNavigation"] = "hn-pending-changes-attempt-navigation";
})(CommonEvents || (CommonEvents = {}));
export var WebComponents;
(function (WebComponents) {
    WebComponents["Promopanel"] = "hn-webcomp-cms-block-promopanel";
})(WebComponents || (WebComponents = {}));
export var StepEvents;
(function (StepEvents) {
    StepEvents["Navigate"] = "hn-step-navigate";
    StepEvents["SendFromWebComponent"] = "hn-step-send-from-web-component";
})(StepEvents || (StepEvents = {}));
